import { render, staticRenderFns } from "./materialFlowManagement.vue?vue&type=template&id=d991ece8&scoped=true&"
import script from "./materialFlowManagement.vue?vue&type=script&lang=js&"
export * from "./materialFlowManagement.vue?vue&type=script&lang=js&"
import style0 from "./materialFlowManagement.vue?vue&type=style&index=0&id=d991ece8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d991ece8",
  null
  
)

export default component.exports