<template>
    <div style="width: 100%;height: 100%">
        <div style="padding: 20px 0 0 30px;width: 100%;height: 10%">
            <h3 style="height:30px ">最近订单</h3>
        </div>
        <div style="width: 100%;height: 100%;">
            <el-table
                :data="tableData"
                stripe
                style="width: 100%">
                <el-table-column
                    prop="date"
                    label="订单编号"
                    width="200">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="订单金额(元)"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="下单时间"
                    width="250">
                </el-table-column>
                <el-table-column
                    prop="tradingdate"
                    label="交易完成日期"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="id"
                    label="买家ID">
                </el-table-column>
                <el-table-column
                    prop="controls"
                    label="操作">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [{
                date: '20230412',
                name: '2000',
                address: '2023-04-12 16:36:26',
                tradingdate:'2023-04-12',
                id:'uimaker',
                controls:'查看'
            }, {
                date: '20230412',
                name: '2000',
                address: '2023-04-12 16:36:26',
                tradingdate:'2023-04-12',
                id:'uimaker',
                controls:'查看'
            }, {
                date: '20230412',
                name: '2000',
                address: '2023-04-12 16:36:26',
                tradingdate:'2023-04-12',
                id:'uimaker',
                controls:'查看'
            }, {
                date: '20230412',
                name: '2000',
                address: '2023-04-12 16:36:26',
                tradingdate:'2023-04-12',
                id:'uimaker',
                controls:'查看'
            },{
                date: '20230412',
                name: '2000',
                address: '2023-04-12 16:36:26',
                tradingdate:'2023-04-12',
                id:'uimaker',
                controls:'查看'
            },{
                date: '20230412',
                name: '2000',
                address: '2023-04-12 16:36:26',
                tradingdate:'2023-04-12',
                id:'uimaker',
                controls:'查看'
            }]
        }
    }
}
</script>