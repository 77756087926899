<template>
    <div style="width: 100%;height: 100%;">
         <div style="width: 1600px;height: 60px;background: #FBF1D7;overflow: hidden">
             <img style="width: 26px;height: 22px;margin: 18px 10px 0 30px ;float: left" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/%E8%AD%A6%E5%91%8A%20(2).png">
             <div style="height: 24px;font-size: 20px;font-weight: 400;float: left;margin-top: 15px">诚信保证金不足，已限权</div>
             <div style="color: #2970FF;height: 21px;font-size: 18px;float: right;margin: 18px 30px;cursor: pointer">查看保证金规则 ></div>
         </div>
        <div style="width: 1600px;height: 280px;background: #FFFFFF;margin-top: 10px">
            <template>
                <div style="display: flex">
                    <span style="color: #444444;font-size: 18px;font-weight:bold;margin-left: 30px; padding-top: 15px !important;">诚信保证金</span>
                    <span style="color: #999999;font-size: 14px;margin-left: 5px; padding-top: 18px !important;">缴纳诚信保证金，将有助于获得需求方的信任，获取更多项目机会</span>
                </div>
                <el-divider></el-divider>
            </template>
            <div id="border" style="margin-left: 30px;">
                <p class="border_p">当前保证金余额</p>
                <span class="border_span">保证金会托管到百工宝平台，可随时申请退回</span>
                <div class="bold" style="">￥0.00</div>
            </div>
            <div id="border" style="margin-left: 20px;">
                <p class="border_p">需缴纳保证金金额</p>
                <span class="border_span" >若保证金额度不足，将延长交易账期15天，保证金缴纳足额后解除交易账期</span>
                <div class="bold" >￥1000.00</div>
                <div style="float: right;margin: -30px 20px 30px 0"><el-button size="medium">缴纳保证金</el-button></div>
            </div>
        </div>
        <div style="width: 1600px;height: 450px;background: #FFFFFF;margin-top: 10px">
            <el-tabs v-model="securityDeposit">
                <el-tab-pane label="缴费/退费记录" name="first">
                    <el-empty :image-size="200" description="暂无数据"></el-empty>
                </el-tab-pane>
                <el-tab-pane label="赔付罚款记录" name="second">
                    <el-empty :image-size="200" description="暂无数据"></el-empty>
                </el-tab-pane>
                <el-tab-pane label="违约记录" name="third">
                    <template>
                        <el-table
                            :data="Deposit"
                            border
                            style="width: 100%;">
                            <el-table-column prop="date" label="时间" align="center" >
                                <template slot-scope="scope">
                                    <div style="font-size: 15px;">{{scope.row.time}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="原因" align="center">
                                <template slot-scope="scope">
                                    <div style="font-size: 15px;">{{scope.row.reason}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="罚扣金额" align="center" >
                                <template slot-scope="scope">
                                    <div type="success" class="button-column">
                                        <div style="font-size: 15px;"> <i class="el-icon-coin"></i>{{ scope.row.amount }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="当前保证金余额" align="center">
                                <template slot-scope="scope">
                                    <div type="success" class="button-column">
                                        <div style="font-size: 15px;"> <i class="el-icon-coin"></i>{{ scope.row.securityDepositAtem }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="状态" align="center">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.status == 1" style="font-size: 15px;">已扣保证金</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="操作" align="center">
                                <template>
                                    <div style="font-size: 15px;color:#2970FF;cursor: pointer">查看订单 ></div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-tab-pane>
            </el-tabs>

        </div>
    </div>
</template>
<script>
export default {
    components: {

    },

    computed: {

    },
    created:function(){

    },
    data () {
        return {
            securityDeposit:'first',
            Deposit:[{
                    time:"2023-06-01 10:20:24",
                    reason:"违规违约金罚扣",
                    amount:"50",
                    securityDepositAtem:"950",
                    status:"1",
            },
                {
                    time:"2023-06-01 10:20:24",
                    reason:"违规违约金罚扣",
                    amount:"50",
                    securityDepositAtem:"950",
                    status:"1",
                },
                {
                    time:"2023-06-01 10:20:24",
                    reason:"违规违约金罚扣",
                    amount:"50",
                    securityDepositAtem:"950",
                    status:"1",
                }]
        }
    },
    mounted:function(){

    },
    methods:{

    }
}
</script>
<style scoped lang="scss">
 #border {
     width: 760px;height: 160px;border: 1px solid #BDBFC1;float: left;
     .border_p {
         font-size: 18px;font-weight:bold;margin: 26px 0 10px 40px
     }
     .border_span {
         color: #999999;font-size: 16px;margin-left: 40px;
     }
     .bold {
         color: #2970FF;font-size: 30px;font-weight:bold;margin-top: 10px;margin-left: 35px;
     }
 }

</style>
<style scoped>

/deep/ .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
    background: #F6F6F6;
}

</style>