<template>
    <div style="float: left;width: 100%;">
        <div v-if="data !== 1 && data !== 4 && data != 5 && data != 7"  style="width: 30%;float: left">
            <div  style="width: 100%;height: 48px;margin: 12px 0 0 30px;">
                <div v-for="(item,index) in sortButton" :key="index" style="float: left;">
                    <div style="font-size: 23px;cursor: pointer;width: 200px" @click="shopButton(item.radio)" :class="{select: item.radio === shopText }"><div style="height:30px;margin-left:50px;padding-top: 10px;">{{item.label}}</div></div>
                </div>
            </div>
        </div>
        <div style="float: right;width: calc(60% - 50px);">

            <div  style="width: 50%;float: left;margin-top: 12px;">
                <el-input
                    placeholder="搜索项目..."
                    suffix-icon="el-icon-search"
                    v-model="search">
                </el-input>
            </div>
            <div style="width: 80px;height: 26px;float: left">
                <el-badge :value="100" :max="10" style="width: 20px;height: 50px;float: left;margin-top: 17px;margin-left: 26px;">
                    <img src="~@/assets/imgs/index/notification.png" class="share-button" icon="el-icon-share" type="primary">
                </el-badge>
            </div>
            <div style="width: 30px;height: 30px;float: left;margin-top: 16px;margin-left: 10px;">
                <img :src="user.headIco" style="width: 30px;height: 30px;border-radius:50%">
            </div>
            <div style="float: left;font-weight: bold;font-size: 18px;height: 21px;margin-top: 19px;margin-right: 5px">{{user.nickName}}</div>
        </div>


    </div>
</template>
<script>
export default {
    components: {


    },
    created() {

    },
    comments:{

    },
    props: {
        data: {


        }
    },

    data() {
        return {
            search:"",
            sortButton:[{label:"专业服务",radio:1},{label:"产品设备",radio:2}],
            shopText:1,
        }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },

    },

    mounted() {

    },

    methods: {
        shopButton(index){
            let that = this;
            that.shopText = index;
            that.$parent.shopData(index);
        },


    },

}

</script>
<style scoped>
.select {
    width: 200px;
    height: 48px;
    background:#f2f2f2;
    color: #000000;
    border-radius: 5px 5px 0 0;
}
</style>