<template>
    <div style="">
       <div style="width: calc(100% - 20px);display: flex">
           <div class="olw">
               <img src="~@/assets/imgs/index/grosssales.png" class="nowimg">
               <div style="float: left">
                   <div class="token">￥136,560</div>
                   <div class="token_list">总销售额</div>
               </div>
           </div>
           <div class="olw">
               <img src="~@/assets/imgs/index/generalvisit.png" class="nowimg">
               <div style="float: left">
                   <div class="token">8,846</div>
                   <div class="token_list">总访问量</div>
               </div>
           </div>
           <div class="olw">
               <img src="~@/assets/imgs/index/generalsubscription.png" class="nowimg">
               <div style="float: left">
                   <div class="token">7,560</div>
                   <div class="token_list">总订单数 </div>
               </div>
           </div>
           <div class="olw">
               <img src="~@/assets/imgs/index/payment.png" class="nowimg">
               <div style="float: left">
                   <div class="token">6,560</div>
                   <div class="token_list">支付笔数</div>
               </div>
           </div>
           <div class="olw">
               <img src="~@/assets/imgs/index/generalquotient.png" class="nowimg">
               <div style="float: left">
                   <div class="token">56</div>
                   <div class="token_list">总商品数</div>
               </div>
           </div>
       </div>
        <div style="float: left">
            <!-- 店铺数据     -->
           <div style="width: 1054px;height: 450px;background: #FFFFFF;margin:25px;">
                <curve></curve>
           </div>
            <!--    最近订单    -->
            <div style="width: 1054px;height: 450px;background: #FFFFFF;clear: both;margin-left: 25px;">
                <orderForGoods></orderForGoods>
            </div>
        </div>
        <!--    销售额类别占比    -->
        <div style="width: 520px;height: 926px;background: #FFFFFF;margin-top: 26px;float: left">
            <pieChart></pieChart>
        </div>
    </div>
</template>
<script>
import curve from "./curve";
import pieChart from "./pieChart"
import orderForGoods from "./orderForGoods"
export default {
    components: {
        curve,
        pieChart,
        orderForGoods
    },
    comments:{

    },
    props: {
        data: {


        }
    },

    data() {
        return {


        }
    },

    computed: {


    },

    mounted() {

    },

    methods: {


    },


}

</script>
<style scoped>
.olw {
    width: 300px;
    height: 100px;
    background: #FFFFFF;
    margin-left: 25px;
    margin-top:10px;

}
.nowimg {
   width: 60px;
    height: 60px;
    margin: 25px 0 0 30px;
    float: left;
}
.token {
    color: #444444;height: 35px;font-size: 30px;
    margin-top: 24px;
    margin-left: 10px;

}
.token_list {
    height: 16px;
    font-size: 14px;
    color: #999999;
    margin: 8px;

}
</style>