<template>
    <div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
            <el-menu-item index="1">企业自开</el-menu-item>
            <el-menu-item index="2">个人代开</el-menu-item>
        </el-menu>
        <div style="display: flex;margin: 21px 0 0 30px;">
            <div style="color: #666666;width: 64px;font-size: 15px;margin-top: 8px;">开票时间</div>
            <div class="block" style="margin: 0px 10px;">
                <el-date-picker
                    style="height: 38px;"
                    v-model="time"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </div>
            <div style="color: #666666;width: 64px;font-size: 15px;margin-top: 8px;">开票金额</div>
            <div class="demo-input-suffix" style="margin-left: 5px">
                <el-input
                    prefix-icon="el-icon-coin"
                    v-model="maxamount">
                </el-input>
            </div>
            <div style="margin: 8px 10px;">-</div>
            <div class="demo-input-suffix">
                <el-input
                    prefix-icon="el-icon-coin"
                    v-model="minamount">
                </el-input>
            </div>
            <div style="color: #666666;width: 64px;font-size: 15px;margin-top: 8px;margin: 10px;">发票类型</div>
            <div>
                <el-select v-model="invoicetype" placeholder="请选择">
                    <el-option
                        v-for="item in invoiceList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div style="color: #666666;width: 64px;font-size: 15px;margin-top: 8px;margin: 10px;">发票类型</div>
            <div>
                <el-select style="width: 110px;" v-model="statustype" placeholder="请选择">
                    <el-option
                        style="width: 110px;"
                        v-for="item in makeoutaninvoicestatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div style="width: 88px;height: 38px;background: #2970FF;margin-left: 15px">
                 <div style="color: #FFFFFF;font-size: 16px;margin: 8px 28px;cursor: pointer">筛选</div>
            </div>
        </div>
        <template>
            <el-table
                :data="invoice"
                height="700"
                border
                stripe
                style="width: 100%;margin-top: 7px">
                <el-table-column class="center-label" prop="invoiceid" label="订单编号" width="180" align="center" style="background: #F6F6F6;">
                    <template slot-scope="scope">
                        <div type="success" class="button-column" style="display: flex;justify-content: center;align-items: center;">
                            <div style="display: inline-block;width: 400px;margin-left: 10px;font-size: 16px;">{{ scope.row.invoiceid }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="交易信息" width="300" >
                    <template slot-scope="scope">
                        <div style="display: inline-block;font-size: 15px;"><span style="color: #999999">商家：</span>{{ scope.row.tradename }}</div>
                        <div style="display: inline-block;font-size: 15px;color: #2970FF"><span style="color: #999999">商家详情：</span>{{ scope.row.productcontent }}</div>
                        <div style="display: inline-block;font-size: 15px;"><span style="color: #999999">买家：</span>{{ scope.row.buyer }}(id：{{ scope.row.id }})</div>
                        <div style="display: inline-block;font-size: 15px;"><span style="color: #999999">来源：</span>{{scope.row.source}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="发票信息" width="250">
                    <template slot-scope="scope">
                        <div style="display: inline-block;font-size: 15px;color: #2970FF"><span style="color: #999999">发票类型：</span>{{ scope.row.invoiceType }}</div>
                        <div style="display: inline-block;font-size: 15px;"><span style="color: #999999">抬头类型：</span>{{ scope.row.LookupType }}</div>
                        <div style="display: inline-block;font-size: 15px;"><span style="color: #999999">发票抬头：</span>{{ scope.row.invoiceLookup }}</div>
                        <div style="display: inline-block;font-size: 15px;"><span style="color: #999999">收货人：</span>{{ scope.row.ticketsale }}</div>
                        <div style="display: inline-block;font-size: 15px;"><span style="color: #999999">收票人电话：</span>{{ scope.row.telephone }}</div>
                        <div style="display: inline-block;font-size: 15px;"><span style="color: #999999">收票人地址：</span>{{ scope.row.address }}</div>
                    </template>
                </el-table-column>
                <el-table-column class="center-label" label="开票金额" align="center">
                    <template slot-scope="scope">
                        <div type="success" class="button-column" style="display: flex;justify-content: center;align-items: center;">
                            <div style="display: inline-block;font-size: 15px;"> <i class="el-icon-coin"></i>{{ scope.row.payAmt }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column class="center-label" label="申请时间" align="center">
                   <template slot-scope="scope">
                       <div type="success" class="button-column" style="display: flex;justify-content: center;align-items: center;">
                         <div style="display: inline-block;font-size: 15px;">{{ scope.row.time }}</div>
                       </div>
                   </template>
                </el-table-column>
                <el-table-column  class="center-label" label="开票状态" align="center">
                    <template slot-scope="scope">
                        <div type="success" class="button-column" style="display: flex;justify-content: center;align-items: center;">
                            <div v-if="scope.row.status == 1" style="display: inline-block;font-size: 15px;color: #FF7F13">待开票</div>
                            <div v-if="scope.row.status == 2" style="display: inline-block;font-size: 15px;">已寄出</div>
                            <div v-if="scope.row.status == 3" style="display: inline-block;font-size: 15px;color: #67C23A">已签收</div>
                            <div v-if="scope.row.status == 4" style="display: inline-block;font-size: 15px;">已退回</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column class="center-label" label="操作" align="center">
                    <div type="success" class="button-column" style="display: flex;justify-content: center;align-items: center;">
                        <div><el-button size="mini">已开票</el-button></div>
                        <div style="margin-top: 10px"><el-button size="mini">上传物流信息</el-button></div>
                        <div style="margin-top: 10px"><el-button size="mini">查看物流信息</el-button></div>
                    </div>
                </el-table-column>
            </el-table>
        </template>

    </div>
</template>
<script>
export default {
    components: {

    },

    computed: {

    },
    created:function(){

    },
    data () {
        return {
            activeIndex:'1',
            time:"",
            maxamount:"",
            minamount:"",
            invoicetype:"",
            invoiceList:[
                {
                    value: '1',
                    label: '全部状态'
                }, {
                    value: '2',
                    label: '增值税纸质普通发票'
                }, {
                    value: '3',
                    label: '增值税纸质专用发票'
                }, {
                    value: '4',
                    label: '增值税电子普通发票'
                }, {
                    value: '5',
                    label: '增值税电子专用发票'
                }, {
                    value: '6',
                    label: '增值税全电普通发票'
                }, {
                    value: '7',
                    label: '增值税全电专用发票'
                }
            ],
            statustype:"",
            makeoutaninvoicestatus:[
                {
                    value: '1',
                    label: '待开票'
                }, {
                    value: '2',
                    label: '待寄出'
                }, {
                    value: '3',
                    label: '已寄出'
                }, {
                    value: '4',
                    label: '已签收'
                }, {
                    value: '5',
                    label: '已退回'
                }, {
                    value: '6',
                    label: '待作废'
                }, {
                    value: '7',
                    label: '已作废'
                }, {
                    value: '8',
                    label: '已开票'
                }
            ],
            invoice:[
                {
                    invoiceid:'11730117534',
                    tradename:'xxxxx股份有限公司',
                    productcontent:'#环氧防腐面漆钢结构防漆铁艺设备栏杆防锈翻新漆树脂漆#',
                    buyer:'快乐星球',
                    id:'542536',
                    source:"百工宝shop交易",
                    invoiceType:"增值税纸质普通",
                    LookupType:"个人",
                    invoiceLookup:"个人个人",
                    ticketsale:"爱丽丝123",
                    telephone:"1234567897",
                    address:"河北省安阳市金山屯区宝山路32号真新六街坊六街坊",
                    payAmt:"1000",
                    time:"2023-06-01 10:20:24",
                    status:"1"
                },
                {
                    invoiceid:'11730117534',
                    tradename:'xxxxx股份有限公司',
                    productcontent:'#环氧防腐面漆钢结构防漆铁艺设备栏杆防锈翻新漆树脂漆#',
                    buyer:'快乐星球',
                    id:'542536',
                    source:"百工宝shop交易",
                    invoiceType:"增值税纸质普通",
                    LookupType:"个人",
                    invoiceLookup:"个人个人",
                    ticketsale:"爱丽丝123",
                    telephone:"1234567897",
                    address:"河北省安阳市金山屯区宝山路32号真新六街坊六街坊",
                    payAmt:"1000",
                    time:"2023-06-01 10:20:24",
                    status:"2"
                },
                {
                    invoiceid:'11730117534',
                    tradename:'xxxxx股份有限公司',
                    productcontent:'#环氧防腐面漆钢结构防漆铁艺设备栏杆防锈翻新漆树脂漆#',
                    buyer:'快乐星球',
                    id:'542536',
                    source:"百工宝shop交易",
                    invoiceType:"增值税纸质普通",
                    LookupType:"个人",
                    invoiceLookup:"个人个人",
                    ticketsale:"爱丽丝123",
                    telephone:"1234567897",
                    address:"河北省安阳市金山屯区宝山路32号真新六街坊六街坊",
                    payAmt:"1000",
                    time:"2023-06-01 10:20:24",
                    status:"3"
                },
                {
                    invoiceid:'11730117534',
                    tradename:'xxxxx股份有限公司',
                    productcontent:'#环氧防腐面漆钢结构防漆铁艺设备栏杆防锈翻新漆树脂漆#',
                    buyer:'快乐星球',
                    id:'542536',
                    source:"百工宝shop交易",
                    invoiceType:"增值税纸质普通",
                    LookupType:"个人",
                    invoiceLookup:"个人个人",
                    ticketsale:"爱丽丝123",
                    telephone:"1234567897",
                    address:"河北省安阳市金山屯区宝山路32号真新六街坊六街坊",
                    payAmt:"1000",
                    time:"2023-06-01 10:20:24",
                    status:"4"
                },
                {
                    invoiceid:'11730117534',
                    tradename:'xxxxx股份有限公司',
                    productcontent:'#环氧防腐面漆钢结构防漆铁艺设备栏杆防锈翻新漆树脂漆#',
                    buyer:'快乐星球',
                    id:'542536',
                    source:"百工宝shop交易",
                    invoiceType:"增值税纸质普通",
                    LookupType:"个人",
                    invoiceLookup:"个人个人",
                    ticketsale:"爱丽丝123",
                    telephone:"1234567897",
                    address:"河北省安阳市金山屯区宝山路32号真新六街坊六街坊",
                    payAmt:"1000",
                    time:"2023-06-01 10:20:24",
                    status:"4"
                }
            ]
        }
    },
    mounted:function(){

    },
    methods:{

    }
}
</script>
<style scoped>
 /deep/ .el-menu--horizontal>.el-menu-item.is-active {
     font-size: 16px;
     color: #2970FF;
     font-weight: bold;
 }
 /deep/ .el-menu--horizontal>.el-menu-item {
     font-size: 16px;
     font-weight: bold;
 }

 /deep/ .center-label .cell {
     text-align: center;
 }
 .button-column {
     display: flex;
     flex-direction: column;
     text-align: center;
 }

 /deep/ .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
     background: #F6F6F6;
 }
</style>