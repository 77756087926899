<template>
    <div style="width: 100%;height: 100%;">
        <div style="width: 910px;border: 1px solid #BDBFC1;margin: 10px;float: left">
            <div style="margin: 16px;font-size: 14px;font-weight: bold;color: #444444;float: left">系统模板-商家默认模板</div>
            <div style="color:#3376FF;float: right;font-size: 14px;margin: 15px;cursor: pointer">删除</div>
            <div style="float: right;margin-top:15px;color:#999999">|</div>
            <div style="color:#3376FF;float: right;font-size: 14px;margin: 15px;cursor: pointer">修改</div>
            <div style="float: right;margin-top:15px;color:#999999">|</div>
            <div style="color: #999999;font-size: 14px;float: right;margin: 16px;">最后修改时间:2023-01-25 12:02</div>
            <template>
                <el-table
                    :data="expressDelivery"
                    height="100"
                    border
                    style="width: 100%">
                    <el-table-column
                        prop="way"
                        label="运送方式">
                    </el-table-column>
                    <el-table-column
                        prop="freight"
                        label="指定地区城市运费">
                    </el-table-column>
                    <el-table-column
                        prop="firstpiece"
                        label="首件（个）">
                    </el-table-column>
                    <el-table-column
                        prop="amount"
                        label="运费（元）">
                    </el-table-column>
                    <el-table-column
                        prop="successor"
                        label="续件（个）">
                    </el-table-column>
                    <el-table-column
                        prop="amounts"
                        label="运费（元）">
                    </el-table-column>
                </el-table>
            </template>
        </div>
<!--        <div style="border-left: 1px solid #BDBFC1;min-height: 792px;float: left;margin-top: -15px;"></div>-->
<!--        <div style="float: left">-->
<!--            <div style="font-size: 16px;margin: 10px 20px">修改运费模板</div>-->
<!--            <div class="formItem">-->
<!--                <div class="label">模板名称：</div>-->
<!--                <el-input style="width: 320px" v-model="input" placeholder="请输入内容"></el-input>-->
<!--            </div>-->
<!--            <div class="formItem" style="margin-top: 10px">-->
<!--                <div class="label"><em>*</em>发货地：</div>-->
<!--                <AddressSelect @change="selectCity" :address ="city"  :lv="3" :info="false" :multiple="false"></AddressSelect>-->
<!--            </div>-->
<!--            <div class="formItem" style="margin-top: 10px">-->
<!--                <div class="label"><em>*</em>是否包邮：</div>-->
<!--                <el-radio-group v-model="freight">-->
<!--                    <el-radio :label="1">自定义运费</el-radio>-->
<!--                    <el-radio :label="2">包邮</el-radio>-->
<!--                </el-radio-group>-->
<!--            </div>-->
<!--            <div class="formItem" >-->
<!--                <div class="label"><em>*</em>计价方式：</div>-->
<!--                <el-radio-group v-model="valuation">-->
<!--                    <el-radio :label="1">按件数</el-radio>-->
<!--                    <el-radio :label="2">按重量</el-radio>-->
<!--                    <el-radio :label="3">按体积</el-radio>-->
<!--                </el-radio-group>-->
<!--            </div>-->
<!--            <div class="formItem" >-->
<!--                <div class="label">运送方式：</div>-->
<!--                <div class="vlader">除指定的区域外，其余地区的运费均采</div>-->
<!--            </div>-->
<!--            <el-radio-group v-model="radio">-->
<!--                <el-radio :label="1">备选项</el-radio>-->
<!--                <el-radio :label="2">备选项</el-radio>-->
<!--                <el-radio :label="3">备选项</el-radio>-->
<!--                <el-radio :label="3">备选项</el-radio>-->
<!--            </el-radio-group>-->
<!--        </div>-->

    </div>
</template>
<script>
import AddressSelect from "../../../../components/common/city/AddressSelect";
export default {
    components: {
        AddressSelect
    },

    computed: {

    },
    created:function(){

    },
    data () {
        return {
            expressDelivery:[
                {way:'快递',freight:'默认运费',firstpiece:'1',amount:'10.00',successor:'1',amounts:'0.00'},
            ],
            freight: 1,
            valuation:1,
        }
    },
    mounted:function(){

    },
    methods:{
        selectCity(value) {
            this.address = value;
        },
    }
}
</script>
<style scoped>
/deep/ .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
    background: #F6F6F6;
}
.formItem {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 40px;
}

.label {
    height: 40px;
    font-size: 16px;
    margin-top: 15px;
    margin-left: 30px;
}
em{
    color:#FF0000;
}
.vlader {
    font-size: 14px;
    color:#999999 ;
}
</style>