<template>
	<div>
		<div style="width: 100%;height:100%;overflow: hidden;display: flex">
            <div>
                <!-- 服务分类部分 -->
                <div style="width: 1000px;height: 460px;background: #FFFFFF">
                    <div class="service-classification-container">
                        <div v-if="shopType == 1">
                            <div class="title">选择服务分类</div>
                            <div class="input-container-d">
                                <el-cascader
                                    ref="cascaderArr"
                                    class="cascader"
                                    v-model="type"
                                    :options="type_list"
                                    @change="handleChange()"
                                    placeholder="请选择服务分类"
                                ></el-cascader>
                            </div>
                        </div>
                        <div v-if="shopType == 2">
                            <div class="title">选择产品分类</div>
                            <div class="input-container-d">
                                <el-cascader
                                    ref="cascaderArr"
                                    class="cascader"
                                    v-model="type"
                                    :options="TypeList"
                                    @change="handleChange()"
                                    placeholder="请选择产品分类"
                                ></el-cascader>
                            </div>
                        </div>

                            <div v-if="specificationsGroup.length >= 1"  style="clear: both;padding-top: 10px;">
<!--                                       <div style="float: left;margin: 10px 0 0 30px;">-->
<!--                                           <el-button type="text">全部</el-button>-->
<!--                                       </div>-->
<!--                                    <div  v-for="(item, index) in specificationsGroup" style="float: left" :key="index">-->
<!--                                        <div class="codeTitle">-->
<!--                                            <div style="margin: 10px 0 0 10px;"  @click =selectIndex(index)>-->
<!--                                                <el-button type="text">{{ item.typeName }}</el-button>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                <div style="width: 930px;height: 364px;margin-left: 30px;clear: both">
                                    <el-table
                                        :data="specificationsGroup"
                                        border
                                        height="350"
                                        style="width: 100%;">
                                        <el-table-column
                                            prop="name"
                                            label="模板名称"
                                            width="300"
                                            align="center">
                                        </el-table-column>
                                        <el-table-column
                                            v-if="shopType === 1"
                                            prop="typeName"
                                            label="服务分类"
                                            width="200"
                                            align="center">
                                        </el-table-column>
                                        <el-table-column
                                            v-if="shopType === 2"
                                            prop="typeName"
                                            label="产品分类"
                                            width="200"
                                            align="center">
                                        </el-table-column>
                                        <el-table-column
                                            fixed="right"
                                            label="操作" align="center">
                                            <template slot-scope="scope">
                                                <el-button  @click =selectIndex(scope.$index)  type="text" size="small">右侧展开显示 ></el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                            <div v-else style="clear: both"><el-empty description="当前分类下还没有保存的属性模板"></el-empty></div>
                        </div>
                </div>
                <!--添加规格 -->
                <div style="width: 1000px;height:auto;overflow: hidden;margin-top: 10px;">
                    <div class="container" v-if="show==='edit'">
	                    <div class="button-container">
		                    <div v-for="(item, index) in specificationsArr" :key="index">
			                    <div class="button">
				                    <el-button
					                    plain
					                    size="mini"
					                    round
					                    @click="addSpecifications(index, item.type, 0)"
				                    >
					                    添加{{ item.name }}规格
				                    </el-button>
			                    </div>
		                    </div>
	                    </div>
                        <div class="content">
                            <div v-for="(item, index) in specificationsList" :key="index">
                                <div v-if="item.type === 1 || item.type === 2" class="specification">
                                    <div class="input-container">
                                        <el-input required v-model="item.key" placeholder="规格名称">
	                                        <template slot="prepend">
		                                        <el-button type="danger" size="mini" @click="deleteSpecification(index)">
			                                        删除
		                                        </el-button>
	                                        </template>
                                        </el-input>
                                    </div>
                                    <div class="input-text">
	                                    <div class="options-input-container">
                                        <el-input
                                            v-if="item.type === 1"
                                            v-model="item.value"
                                            disabled
                                            placeholder="文本型输入框"
                                        >
                                        </el-input>
                                        <el-input
                                            v-if="item.type === 2"
                                            v-model="item.value"
                                            disabled
                                            placeholder="数字型输入框"
                                        >
                                        </el-input>
	                                    </div>
                                    </div>
	                                <el-radio v-model="item.userType" :label="1" border>商家选择</el-radio>
	                                <el-radio v-model="item.userType" :label="2" border>用户选择</el-radio>
                                </div>
                                <div v-if="item.type === 3 || item.type === 4 || item.type === 5 || item.type === 6" class="options-container">
	                                <div style="width: 100%;height:auto;overflow: hidden">
		                                <div class="input-container">
			                                <el-input v-model="item.key" placeholder="规格名称">
				                                <template slot="prepend"><el-button type="danger" size="mini" @click="deleteSpecification(index)">
					                                删除
				                                </el-button></template>
			                                </el-input>
		                                </div>
		                                <div class="input-text">
			                                <div class="options-input-container">
				                                <el-input
					                                v-model="item.optionName"
					                                :disabled="item.type === 3 && item.value.length >= 2"
					                                placeholder="请输入选项"
				                                >
					                                <template slot="append"> <el-button
						                                :disabled="item.type === 3 && item.value.length >= 2"
						                                @click="addArrValue(index, 0, 0)"
						                                class="options-button"
					                                >
						                                添加
					                                </el-button></template>
				                                </el-input>
			                                </div>
		                                </div>
		                                <el-radio v-model="item.userType" :label="1" border>商家选择</el-radio>
		                                <el-radio v-model="item.userType" :label="2" border>用户选择</el-radio>
	                                </div>
                                    <div style="width:100%;height: auto;" v-if="item.type !== 6">
                                        <div v-for="(v, i) in item.value" :key="i" class="checkbox-container">
                                            <el-checkbox v-if="item.type === 4" disabled v-model="v.radio" :label="v.radio">
                                               <span style="line-height: 40px;color:#c1c1c1">{{ v.name }}</span>
                                            </el-checkbox>
                                            <el-radio v-else disabled v-model="item.radio" :label="v.radio">
	                                            <span style="line-height: 40px;color:#c1c1c1">{{ v.name }}</span>
                                            </el-radio>
                                        </div>
                                    </div>
	                                <div v-else>
		                                <div v-for="(v, i) in item.value" :key="i" class="checkbox-container2">
			                                <div style="width: 100%;height: auto;">
<!--				                                <el-checkbox  v-model="v.radio" disabled :label="v.radio">-->
<!--					                                <span style="line-height: 40px;color:#c1c1c1">{{ v.name }}</span>-->
<!--				                                </el-checkbox>-->
				                                <el-radio disabled v-model="item.radio" :label="v.radio">
					                                <span style="line-height: 40px;color:#c1c1c1">{{ v.name }}</span>
				                                </el-radio>
				                                <el-tag
					                                :key="p"
					                                v-for="p in v.value"
					                                closable
					                                :disable-transitions="false"
					                                @close="handleClose(index,i,p)">
					                                {{p}}
				                                </el-tag>
				                                <el-input
					                                class="input-new-tag"
					                                v-if="v.inputVisible"
					                                v-model="v.inputValue"
					                                ref="saveTagInput"
					                                size="small"
					                                @keyup.enter.native="handleInputConfirm(index,i)"
					                                @blur="handleInputConfirm(index,i)"
				                                >
				                                </el-input>
				                                <el-button v-else class="button-new-tag"  size="small" @click="showInput(index,i)">+ New</el-button>
			                                </div>
		                                </div>
	                                </div>
                                </div>
                            </div>
                            <div class="save-button">
                                <div class="button-container-button"><el-button size="medium" round  class="input-container-button button-container-height" @click="addSpecificationsGroup()" v-if="specificationsList.length > 0">保存规格属性</el-button></div>
                                <div class="button-container-button"><el-button size="medium" round  class="input-container-button button-container-height" @click="addSpecificationsGroup('add')" v-if="specificationsList.length > 0 && specificationsIndex!==''">另存为新规格属性</el-button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 80px;background: #FFFFFF;width: 1000px;margin: 10px 0 0 1px;float: left">
                    <div class="button-container-button"><el-button size="medium" round  class="input-container-button button-container-height" @click="newSpecifications">添加一组新属性</el-button></div>
                </div>
            </div>
            <!--预览部分 -->
            <div v-if="show==='look' && specificationsGroup.length !== 0 " style="width: 490px;float: left;margin-left: 10px;">
                <div class="containerdiv" >
                    <div class="title">属性显示</div>
                    <div class="nested-container">
                        <div class="form-container" >
                            <el-form ref="form" v-for="(item,index) in specificationsList" :key="index" label-position="right" :model="item" >
                                <el-form-item :label="item.key" label-position="right" label-width="70px">
                                    <el-input v-if="item.type===1" v-model="item.value" type="text" placeholder="文本型输入框"></el-input>
                                    <el-input v-if="item.type===2" v-model="item.value" type="number" placeholder="数字型输入框"></el-input>
                                    <label v-if="item.type===3 || item.type===5">
                                        <el-radio border size="mini" v-for="(v,i) in item.value" :key="i" v-model="item.radio" :label="v.radio">{{ v.name }}</el-radio>
                                    </label>
                                    <el-checkbox-group v-if="item.type===4" v-model="item.selectList" size="mini">
                                        <el-checkbox-button v-for="(v,i) in item.value" :key="i" :label="v.radio">{{ v.name }}</el-checkbox-button>
                                    </el-checkbox-group>
                                    <div v-if="item.type===6">
	                                    <div style="width: calc(100% - 2px);border:1px solid blue" >
		                                    <div style="width: 100%;height:auto;overflow:hidden;" v-for="(p,pindex) in item.value" :key="pindex" :style="pindex==item.value.length-1?'':'border-bottom: 1px solid blue'">
			                                    <div style="width: 25%; height: auto; overflow: hidden; float: left;text-align: center;align-items: center;  display: flex;">
				                                    <el-radio size="mini" style="margin: auto"  v-model="item.radio" :label="p.radio">{{ p.name }}</el-radio>
			                                    </div>
			                                    <div style="width: calc(75% - 2px);height: auto;overflow:hidden;border-left: 1px solid blue">
				                                    <div style="width: 100%;line-height: 40px;height:auto;word-wrap: break-word;;overflow:hidden;text-align: center" v-for="(k,kindex) in p.value" :key="kindex" :style="kindex==p.value.length-1?'':'border-bottom: 1px solid blue'">
					                                    {{ k }}
				                                    </div>
			                                    </div>
		                                    </div>
	                                    </div>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div style="width: 450px;display: flex;justify-content: center">
                            <el-button size="medium" round  class="input-container-button button-container-height" @click="show='edit'">编辑属性</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="show !== 'look'" style="width: 490px;float: left;margin-left: 10px;background: #FFFFFF;">
                <div class="title">属性显示</div>
                <div style="width: 100%;height: 100%"><el-empty description="暂无显示"></el-empty></div>
            </div>
		</div>
	</div>
</template>
<script>

// import DynamicTable from "@/pages/shop/components/DynamicTable.vue";

export default {
	name: "specifications",
	components: {
	
	},
	computed: {},
    props:{
	    shopType:{
	        type:Number,
            default(){return 1}
        }
    },
	data () {
		return {
			type_list:[],
			type:[],
			specificationsList:[],
			//specificationsType:{key:"",value:"",type:0},//1.字符串型2数字型3逻辑4数组4数组
			show:'',
			specificationsArr:[{name:"文本",type:1},{name:"数字",type:2},{name:"逻辑",type:3},{name:"多选",type:4},{name:"单选",type:5},{name:"嵌套",type:6}],
			specificationsGroup:[],
			typeName:'',
			specificationsIndex:"",
            maxHeight:300,
            TypeList:[],
			lookTableValue:false,
			inputVisible: false,
			inputValue: ''
		}
	},
    watch:{
        shopType:{
            handler(){
                this.specificationsGroup = "";
                this.type = [];
                this.specificationsList = [];
                this.specificationsIndex = "";
                this.show = "";
                // this.specificationsGroup = "";
                // return this.show !== 'look';
            }
        },
	    show:{
			handler(){
			    if (this.show === 'edit'){
					console.log("edit++++++++++++++")
				    for (let i = 0; i < this.specificationsList.length; i++) {
					   if (this.specificationsList[i].type === 1 || this.specificationsList[i].type === 2){
						   console.log(this.specificationsList[i].value)
						   this.specificationsList[i].value = "";
					   }
				    }
			    }
		    }
	    }
    },
	mounted:function(){
		var that = this;
		that.getTypeList();
		that.getEquipmentTypeList()

	},
	methods:{
		/**
		 * 删除一个标签
		 * @param index 第一级
		 * @param i 第二级
		 * @param tag 标签
		 */
		handleClose(index,i,tag) {
			this.specificationsList[index].value[i].value.splice(this.specificationsList[index].value[i].value.indexOf(tag), 1);
		},
		/**
		 * 添加一个标签时获取焦点
		 * @param index
		 * @param i
		 */
		showInput(index,i) {
			this.specificationsList[index].value[i].inputVisible = true;
			this.$nextTick(() => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		/**
		 * 失去焦点添加一个标签
		 * @param index
		 * @param i
		 */
		handleInputConfirm(index,i) {
			let inputValue = this.specificationsList[index].value[i].inputValue;
			if (inputValue) {
				this.specificationsList[index].value[i].value.push(inputValue);
				this.specificationsList[index].value[i].inputValue="";
				this.specificationsList[index].value[i].inputVisible = false;
			}
			
		},
		
		
		/**添加一个类型
		 * type  1.字符串型2数字型3逻辑4数组4数组5多选一6嵌套
		 * index 嵌套型的时候判断是第几个value
		 * subType 0 不是嵌套1嵌套
		 */
		addSpecifications: function(index, type, subType) {
		    if (this.type == ""){
                this.utils.err("请先选择分类");return false;
            }
			let newSpecifications = {
				key:'',
				type: type,
				value: "",
				userType:1,
			};
			if (type === 3 || type === 5 || type === 6) {
				newSpecifications.value = [];
				newSpecifications.radio= 0;
				newSpecifications.optionName="";
			} else if (type === 4) {
				newSpecifications.selectList = [];
				newSpecifications.optionName="";
				newSpecifications.value = [];
			}else if (type===7){
				newSpecifications.value=[];
			}
			if (subType === 0) {
				this.specificationsList.push(newSpecifications);
			} else {
				this.specificationsList[index].value.push(newSpecifications);
			}
		},
		newSpecifications(){
			let that = this;
			that.specificationsList = [];
			that.specificationsIndex = "";
			that.show = 'edit';
		},
		/**
		 *
		 * @param index 哪个一级选项
		 * @param idx 哪个子选项
		 * @param subType 1是子选项上添加值
		 * @returns {boolean}
		 */
		addArrValue(index,idx,subType){
			let that = this;
			let optionName = that.specificationsList[index].optionName;
			let radio = that.specificationsList[index].value.length;
			let path = that.specificationsList[index].value;
			if (subType===1) {
				optionName = that.specificationsList[index].value[idx].optionName;
				radio = that.specificationsList[index].value[idx].value.length;
				path = that.specificationsList[index].value[idx].value
			}
			if(optionName===""){
				that.utils.err("选项名不可为空");return false;
			}
			let option = {
				name:optionName,
				radio:radio
			}
			if ( that.specificationsList[index].type===6){
				option.value=[];
				option.inputVisible = false;
				option.inputValue = '';
			}
			path.push(option)
			if (subType===1){
				that.specificationsList[index].value[idx].optionName = ""
			}else {
				that.specificationsList[index].optionName = ""
			}
		},
		/**
		 * 选择是哪一个
		 */
		selectIndex(index){
			this.specificationsList = JSON.parse(JSON.stringify(this.specificationsGroup[index].data));
			this.specificationsIndex = index;
			this.show = 'look';
		},
		//保存规格属性
		addSpecificationsGroup(type){
			const that = this;
			if (this.typeName===""){
				this.utils.err("请选择分类");return false;
			}
			if (type==='add'){
				this.specificationsIndex = "";
			}
			for (let i = 0; i < this.specificationsList.length; i++) {
				if (this.specificationsList[i].type===6){
					for (let j = 0; j < this.specificationsList[i].value.length; j++) {
						if(this.specificationsList[i].value.key===""){
							this.utils.err("规格名称不可为空");return false;
						}
					}
				}else {
					if(this.specificationsList[i].key===""){
						this.utils.err("规格名称不可为空");return false;
					}
				}
			}
			let inputValue = "";
			let id="";
			if (this.specificationsIndex!==""){
				inputValue = this.specificationsGroup[this.specificationsIndex].name
				id= this.specificationsGroup[this.specificationsIndex]._id
			}
			this.$prompt('请输入名称', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputValue:inputValue,
			}).then(({ value }) => {
				if(!value) {
					this.$message({
						type: 'error',
						message: '名称不能为空'
					});
					return false;
				}

				const data = {
					_id:id,
					name: value,
					typeName:this.typeName,
					type:JSON.stringify(this.type),
					data: JSON.stringify(this.specificationsList),
                    menu: this.shopType == 1 ? 'serves' : 'goods'
				};
				that.newApi.addSpecificationsGroup(data).then((res)=>{
                    data.type = JSON.parse(data.type);
                    data.data = JSON.parse(data.data);
					if (this.specificationsIndex===""){
						data._id = res.data;
						this.specificationsGroup.unshift(data);
						this.specificationsIndex = 0;
					}else {
						this.specificationsGroup[this.specificationsIndex] = data;
					}
					this.show = 'look'
					this.$message({
						type: 'success',
						message: "保存成功"
					});
				}).catch((err)=>{
					this.$message({
						type: 'success',
						message: "保存失败"
					});
					console.log(err)
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '取消保存'
				});
			});
		},
        handleChange() {
            const that = this;
            const checkedNode = this.$refs["cascaderArr"].getCheckedNodes();
            this.typeName = checkedNode[0].data.label;
            this.newApi.getTypeSpecificationsGroup({
                type: JSON.stringify(this.type),
                menu: this.shopType == 1 ? 'serves' : 'goods',
            }).then((res) => {
                that.specificationsGroup = res.data;
                that.show = "";
            });
        },
        // 删除规格
        deleteSpecification(index,idx,subType) {
			console.log(index,idx)
		    let that = this;
			if (subType==1){
				that.specificationsList[index].value.splice(idx, 1);
				return false;
			}else {
				that.utils.confirm("确定移除？", function() {
					that.specificationsList.splice(index, 1);
				})
			}
		    
        },
		deleteSpecificationsub(index){
			let that = this;
			that.utils.confirm("确定移除？", function() {
				that.specificationsList.splice(index, 1);
			})
		},
		//获取类型列表
		getTypeList(){
			var that = this;
			that.newApi.getTypeList({
				lv: 3,
			}).then((res)=>{
				that.type_list = res.data;
			}).catch((err)=>{
				console.log(err)
			})
		},
        // shopButton(index){
		//     let that = this;
        //     that.shopText = index;
        // },

    //    获取产品分类
        getEquipmentTypeList(){
            var that = this;
            this.newApi.getEquipmentTypeList({
                lv:3
            }).then((res)=>{
                that.TypeList = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },


	}
}
</script>
<style scoped>
.containerAll {
	width: 490px;
	height: 550px;
    /*overflow: hidden;*/
	background: #fff;
	float: left;
    margin-left: 10px;
}

.item {
	width: calc(100%- 40px);
	height: 50px;
	line-height: 50px;
	padding: 5px 20px;
	font-size: 30px;
}

.service-classification-container {
	width: 100%;
	background: #FFFFFF;
	float: left;
}

.title {
	height: 24px;
	font-size: 20px;
	color: #444444;
	margin: 0 0 0 30px;
	padding-top: 20px;
	font-weight: bold;
}

.input-container-d {
	width: 430px;
	background: #FFFFFF;
	margin: 12px 0 0 30px;
    float: left;
}

.cascader {
	width: 430px;
}
.containerdiv {
	width: 100%;
	float: left;
	background: #FFFFFF;
}

.form-container {
	width: 95%;
	min-height: 300px;
	height: auto;
	overflow: hidden;
	background: #FFFFFF;
	float: left;
}

.input-container-button {
	width: 80%;
}

.nested-container {
	margin-top: 10px;
}

.button-container-button {
    width: 1000px;
    height: 80px;
}

.button-container-height {
	margin: 20px 5%;
	height: 40px;
}
.container {
	padding-top: 10px;
	height: auto;
	clear: both;
	width: 100%;
	background: #FFFFFF;
}

.header {
	width: 100%;
	height: auto;
	overflow: hidden;
	background: #FFFFFF;
	float: left;
}

.button-container {
	width: 100%;
	height: 98px;
	float: left;
	margin-bottom: 10px;
	margin-left: 1%;
}

.button {
	width: calc(100% / 6 - 20px);
	margin-top: 20px;
	margin-right: 1%;
	float: left;
    margin-left: 10px;
}

.content {
	width: calc(100% - 20px);
	min-height: 300px;
	height: auto;
	padding: 0 10px;
	overflow: hidden;
}

.specification {
	width: 100%;
	margin-top: 10px;
	background: #f1f1f1;
	height: 40px;
	padding: 5px 0;
}

.input-container {
	width: 20%;
	float: left;
	height: auto;
	overflow: hidden;
}

.input-text {
	width: 50%;
	float: left;
	height: 40px;
}

.button-delete {
	width: 50%;
	float: left;
	height: 30px;
	padding: 5px 10px;
}

.options-container {
	width: 100%;
	background: #f1f1f1;
	height: auto;
	overflow: hidden;
	min-height: 40px;
	margin-top: 10px;
	padding: 5px 0;
}

.options-input-container {
	width: 70%;
	height: 40px;
	float: left;
}

.options-input {
	width: 70%;
	float: left;
}

.options-button-container {
	width: 30%;
	float: left;
}

.options-button {
	width: 100%;
}

.options-delete-button {
	width: 30%;
	float: left;
	height: 40px;
}

.checkbox-container,
.radio-container {
	width: auto;
	margin: 0 20px;
	height: auto;
	min-height: 40px;
	line-height: 40px;
	float: left;
	overflow: hidden;
}
.checkbox-container2,
.radio-container {
	width: 100%;
	margin: 0 20px;
	height: auto;
	min-height: 40px;
	line-height: 40px;
	float: left;
	overflow: hidden;
}


.nested-specification {
	width: 100%;
	background: #f1f1f1;
	height: auto;
	overflow: hidden;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-top: 10px;
}

.nested-header {
	width: 100%;
	float: left;
	height: 40px;
	margin: 5px 0;
}

.nested-input-container {
	width: 70%;
	float: left;
	height: 40px;
}

.nested-button-container {
	width: 30%;
	float: left;
	height: 40px;
}
.codeTitle{
    overflow: hidden;
    overflow-y: scroll;
    max-height: 100px;
    line-height: 26px;
}
.codeTitle::-webkit-scrollbar {
    display: none;
}

.el-tag + .el-tag {
	margin-left: 10px;
}
.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
.input-new-tag {
	width: 200px;
	margin-left: 10px;
	vertical-align: bottom;
}

</style>
