<template>
    <div style="width: 100%;height: 900px">
        <div style="padding: 20px 0 0 30px; width: 100%; height: 50%">
            <h3 style="height: 30px;">销售额类别占比</h3>
            <div style="width: calc(100% - 30px); height: calc(100% - 50px)" id="shop"></div>
        </div>
        <div style="width: 90%; height: calc(50% - 20px);margin-left: 5px">
            <div v-for="(data, index) in chartData" :key="index" style="margin-bottom: 10px;">
                    <span>{{ data.name }}</span>
                    <el-progress
                        :text-inside="true"
                        :stroke-width="24"
                        :percentage="getPercentage(data.value)"
                        :color="chartColors[index % chartColors.length]"
                        class="progress"
                    ></el-progress>
            </div>
        </div>

    </div>
</template>
<script>
export default {

    components: {

    },
    comments:{

    },
    props: {
        data: {


        }
    },

    data() {
        return {
            chartData: [
                { value: 1048, name: '品牌策划' },
                { value: 735, name: '公装服务' },
                { value: 580, name: '插画设计' },
                { value: 484, name: '应用UI设计' },
                { value: 300, name: '新媒体投放' },
                { value: 360, name: '产品包装设计' }
            ],
            totalValue: 0,
            chartColors: ['#F5CD01', '#F66232', '#A575EC', '#7666F8', '#657797 '],

        }
    },

    computed: {


    },

    mounted() {
        this.init()
    },

    methods: {
        init: function() {
            var myChart = new this.$echarts.init(document.getElementById('shop'));
            var option = {
                tooltip: {
                    trigger: 'item'
                },
                color: this.chartColors,
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['50%', '80%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.chartData
                    }
                ]
            };
            myChart.setOption(option);
            this.calculateTotalValue();
        },
        calculateTotalValue: function() {
            this.totalValue = this.chartData.reduce((total, data) => total + data.value, 0);
        },
        getPercentage: function(value) {
            return parseFloat((value / this.totalValue) * 100).toFixed(2);
        }
    },


}

</script>
<style scoped>

</style>