<template>
    <div style="overflow: hidden;width: 100%;display: flex;justify-content: center;">
           <div style="display: flex;overflow: hidden">
                <div style="width: 260px;height: 100%;background: #2B3154;">
                    <div style="width: 160px;height: 50px;padding: 30px 0 0 50px;">
                        <img src="~@/assets/imgs/index/logo2.png">
                    </div>
                    <div class="head" :class="{ active: activeTab === 1 }" @click="changeTab(1)">
                        <div class="shoplog">
                            <img src="../../assets/imgs/index/shoplogo.png">
                        </div>
                        <div class="typeface">店铺首页</div>
                    </div>
                    <div class="head" :class="{ active: activeTab === 2 }" @click="changeTab(2)">
                        <div class="shoplog">
                            <img src="../../assets/imgs/index/shoplogo2.png">
                        </div>
                        <div class="typeface">商品管理</div>
                    </div>
                    <div class="head" :class="{ active: activeTab === 3 }" @click="changeTab(3)">
                        <div class="shoplog">
                            <img src="../../assets/imgs/index/shoplogo3.png">
                        </div>
                        <div class="typeface">订单管理</div>
                    </div>
                    <div class="head" :class="{ active: activeTab === 4 }" @click="changeTab(4)">
                        <div class="shoplog">
                            <img src="../../assets/imgs/index/case.png">
                        </div>
                        <div class="typeface">案例管理</div>
                    </div>
                    <div class="head" :class="{ active: activeTab === 5 }" @click="changeTab(5)">
                        <div class="shoplog">
                            <img src="../../assets/imgs/index/lflow.png">
                        </div>
                        <div class="typeface">物流管理</div>
                    </div>
                    <div class="head" :class="{ active: activeTab === 6 }" @click="changeTab(6)">
                        <div class="shoplog">
                            <img src="../../assets/imgs/index/shoplogo4.png">
                        </div>
                        <div class="typeface">发票管理</div>
                    </div>
                    <div class="head" :class="{ active: activeTab === 7 }" @click="changeTab(7)">
                        <div class="shoplog">
                            <img src="../../assets/imgs/index/shoplogo5.png">
                        </div>
                        <div class="typeface">保证金管理</div>
                    </div>
                    <div class="head" :class="{ active: activeTab === 8 }" @click="changeTab(8)">
                        <div class="shoplog">
                            <img src="../../assets/imgs/index/shoplogo6.png">
                        </div>
                        <div class="typeface">属性模板管理</div>
                    </div>
                </div>
                <div>
                    <!--  head头部 -->
                    <div style="width: 1900px;height: 60px;background: #FFFFFF;">
                        <shopHead :data="activeTab"></shopHead>
                    </div>
                    <!--  店铺首页 -->
                    <div v-if="activeTab === 1" style="height: auto;overflow: hidden;">
                        <shopHomepage></shopHomepage>
                    </div>
                    <!--   商品管理   -->
                    <div v-if="activeTab === 2" style="width: 1600px;height:auto;background: #FFFFFF;margin: 20px;">
                        <serviceManagement :shopType="shopType" :myshop="myshop"></serviceManagement>
                    </div>
                    <!--   订单管理  -->
                    <div v-if="activeTab === 3" style="height: 100%;overflow: hidden;">
                        <orderManagement :shopType="shopType"></orderManagement>
                    </div>
                    <!--  案例管理 -->
                    <div v-if="activeTab === 4" style="width: 1600px;height:100%;background: #FFFFFF;margin: 20px;">
                        <caseManagement :myshop="myshop"></caseManagement>
                    </div>

                    <!--   物流管理  -->
                    <div  v-if="activeTab === 5" style="min-height: 831px;width: 1600px;background: #FFFFFF;margin: 20px">
                        <materialFlowManagement></materialFlowManagement>
                    </div>
                    <!--   发票管理  -->
                    <div  v-if="activeTab === 6" style="min-height: 831px;width: 1600px;background: #FFFFFF;margin: 20px">
                        <invoiceManagement></invoiceManagement>
                    </div>
                    <!--  保证金管理   -->
                    <div v-if="activeTab === 7" style="min-height: 831px;width: 1600px;margin: 20px">
                        <securityDepositManagement></securityDepositManagement>
                    </div>
                    <!--  属性模板管理 -->
                    <div v-if="activeTab === 8" style="width: 1500px;min-height: 850px;margin: 20px;">
                        <test :shopType="shopType"></test>
                    </div>
                </div>

            </div>
    </div>
</template>
<script>
import shopHead from "./components/backstage/shopHead";
import shopHomepage from "./components/backstage/shopHomepage";
import serviceManagement from "./components/backstage/serviceManagement";
import Test from "./test";
import orderManagement from  "./components/backstage/orderManagement";
import caseManagement from  "./components/backstage/caseManagement"
import invoiceManagement from  "./components/backstage/invoiceManagement"
import materialFlowManagement from  "./components/backstage/materialFlowManagement"
import securityDepositManagement from  "./components/backstage/securityDepositManagement"
export default {
    components: {
        Test,
        shopHead,
        shopHomepage,
        serviceManagement,
        orderManagement,
        caseManagement,
        invoiceManagement,
        materialFlowManagement,
        securityDepositManagement
    },
    comments:{

    },
    props: {
        data: {


        }
    },

    data() {
        return {
            search:"",
            activeTab: 1,
            myshop:{},
            shopType:1,

        }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },

    },

    mounted() {
        if (this.$route.query.type){
            this.activeTab = parseInt(this.$route.query.type);
        }
        this.getMyShop()
    },

    methods: {
        changeTab(tab) {
            this.activeTab = tab;
        },
        /**
         * 接收点击服务或产品
         */
        shopData(label){
            this.shopType = label
        },
        // 查询我的店铺
        getMyShop(){
            var that= this;
            that.newApi.getMyShop({}).then((ret)=>{
                that.myshop = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },

    },

}

</script>
<style scoped>
.head {
    height: 57px;
    width: 242px;
    border-radius: 29px 0px 0px 29px;
    margin-left: 18px;
    margin-top: 30px;
    cursor: pointer;
}

.head.active {
    background: #191E41;
    color: #191E41;
}

.shoplog {
    width: 22px;
    height: 22px;
    padding: 16px 0 0 35px;
    float: left;
}

.typeface {
    color: #FFFFFF;
    height: 21px;
    font-size: 18px;
    float: left;
    margin: 16px 0 0 15px;
}

</style>