<template>
    <div style="width: 100%;height: 100%">
        <div style="padding: 20px 0 0 30px;width: 100%;height: 100%">
            <h3 style="height:30px ">店铺数据</h3>
            <div style="width:calc(100% - 30px);height: calc(100% - 50px)" id="div"></div>
        </div>
    </div>
</template>
<script>
export default {

    components: {

    },
    comments:{

    },
    props: {
        data: {


        }
    },

    data() {
        return {


        }
    },

    computed: {


    },

    mounted() {
       this.init()
    },

    methods: {
        init : function () {
            var myChart = new this.$echarts.init(document.getElementById('div'));
            var option = {
                legend: {
                    data: ['访问量', '支付笔数'],
                    icon: 'circle',
                },
                title: {
                    show: false
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: [
                            '星期一',
                            '星期二',
                            '星期三',
                            '星期四',
                            '星期五',
                            '星期六',
                            '星期天'
                        ]
                    }
                ],
                yAxis: [
                    {
                        min: function(value) {//取最小值向下取整为最小刻度
                            return Math.floor(value.min)
                        },
                        max: function(value) {//取最大值向上取整为最大刻度
                            return  Math.ceil(value.max)
                        },
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '访问量',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                        name: '支付笔数',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        data: [220, 182, 191, 234, 290, 330, 310]
                    }
                ]
            };
            myChart.setOption(option)
        },

    },


}

</script>