<template>
    <div class="order">
        <div style="width: calc(100% - 20px);display: flex">
            <div class="olw">
                <img src="~@/assets/imgs/index/generalsubscription.png" class="nowimg">
                <div style="float: left">
                    <div class="token">7,560</div>
                    <div class="token_list">总订单数 </div>
                </div>
            </div>
            <div class="olw">
                <img src="~@/assets/imgs/index/payment.png" class="nowimg">
                <div style="float: left">
                    <div class="token">6,560</div>
                    <div class="token_list">支付笔数</div>
                </div>
            </div>
            <div class="olw">
                <img src="~@/assets/imgs/index/grosssales.png" class="nowimg">
                <div style="float: left">
                    <div class="token">￥136,560</div>
                    <div class="token_list">总销售额</div>
                </div>
            </div>
            <div class="olw">
                <img src="~@/assets/imgs/index/generalvisit.png" class="nowimg">
                <div style="float: left">
                    <div class="token">8,846</div>
                    <div class="token_list">订单金额(元)</div>
                </div>
            </div>
            <div class="olw">
                <img src="~@/assets/imgs/index/generalquotient.png" class="nowimg">
                <div style="float: left">
                    <div class="token">56</div>
                    <div class="token_list">毛利金额(元)</div>
                </div>
            </div>
        </div>
        <div class="order-body">
            <el-tabs v-model="status" @tab-click="getOrderList" v-if="shopType === 1">
                <el-tab-pane v-for="(item, index) in typeList" :key="index" :label="item.name" :name="item.status">
                    <div style="display: flex">
                        <div>
                            <el-input size="small" style="width: 350px" placeholder="输入商品名称或订单号搜索" v-model="orderName">
                                <template slot="append">订单搜索</template>
                            </el-input>
                        </div>
                        <div style="border-right: 2px #BDBFC1 solid; margin: 2px 30px"></div>
                        <div style="display: flex; margin-top: 2px; margin-right: 20px">
                            <span style="margin-top: 2px">订单金额（元）</span>
                            <el-input style="width: 140px" size="mini" type="number" min="0" v-model="minPrice">
                            </el-input>
                            <span style="color: #BDBFC1; margin: 0 10px">-</span>
                            <el-input style="width: 140px" size="mini" type="number" min="0" v-model="maxPrice">
                            </el-input>
                        </div>
                        <div @click="searchOrder" class="search-btn hand op">筛选</div>
                    </div>
                    <div class="title-box">
                        <el-date-picker
                            style="margin-top: 6px; width: 260px"
                            v-model="orderTime"
                            type="daterange"
                            value-format="yyyy:MM:dd"
                            align="right"
                            unlink-panels
                            size="small"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            @change="checkoutDate">
                        </el-date-picker>

                        <span style="margin: 0 234px 0 40px">订单详情</span>
                        <span>数量</span>
                        <span style="margin: 0 146px 0 120px">委托方</span>
                        <span>金额</span>
                        <span style="margin: 0 116px 0 160px">订单状态</span>
                        <span>操作</span>
                    </div>
                    <div class="title">
                        <div class="order-box" v-for="(item, index) in orderList" :key="index" style="margin-bottom: 10px">
                            <OrderCard :orderData="item"></OrderCard>
                        </div>
                    </div>

                </el-tab-pane>

            </el-tabs>

            <div v-if="shopType === 2">
                <div style="overflow-y: scroll; max-height: 1000px">
                    <ProduceList :type="2"></ProduceList>
                </div>

            </div>
        </div>
    </div>
</template>
<script>

import OrderCard from "../../components/order/OrderCard";
import ProduceList from "../order/ProductList";
export default {
    name: "order",
    components: {ProduceList, OrderCard},
    data() {
        return {
            type: 1,
            orderList: [],
            page: 1,
            pageSize: 20,
            status: '0',
            userType: '0',

            orderName: '',
            minPrice: '',
            maxPrice: '',
            orderTime: '',

            pickerOptions: {
                shortcuts: [{
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近一年',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                        picker.$emit('pick', [start, end]);
                    }
                },
                ]
            },

            typeList: [
                {status: '0', name: '全部'},
                {status: '1', name: '待签约'},
                {status: '2', name: '待缴保证金'},
                {status: '3', name: '待付款'},
                {status: '4', name: '工作中'},
                {status: '5', name: '待验收'},
                {status: '6', name: '待评价'},
                {status: '7', name: '退款中'},
                {status: '8', name: '维权中'},
                {status: '9', name: '已完成'},
                {status: '10', name: '已撤单'}],
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    props: {
        shopType:{
            type:Number,
            default(){return 1}
        },
    },
    created: function () {

    },
    mounted: function () {
        this.getOrderList();
    },
    methods: {
        // 按照时间搜索
        checkoutDate(time) {
            if (time) {
                this.orderTime = time[0] + '-' + time[1]
            } else {
                this.orderTime = ''
            }
            this.getOrderList()
        },

        // 搜索订单
        searchOrder() {
            this.getOrderList()
        },

        // 跳转
        toOption(){
            var that = this;
            if(that.user.userType === 1){
                that.$router.push('/circle/mySpace/' + that.user.uuid)
            }else if (that.user.userType === 2 || that.user.userType === 3)
                that.$router.push('/circle/organSpace/' + that.user.uuid)
        },

        // 获取订单列表
        getOrderList() {
            var param = {};
            param.page = 1;
            param.pageSize = 20;
            param.userType = 2;
            param.status = this.status;
            param.time = this.orderTime;
            this.newApi.getServiceOrderList(param).then(res => {
                this.orderList = res.data
            })
        }
    }
}
</script>
<style scoped>
.olw {
    width: 300px;
    height: 110px;
    background: #FFFFFF;
    margin-left: 25px;margin-top:20px;

}
.nowimg {
    width: 60px;
    height: 60px;
    margin: 25px 0 0 30px;
    float: left;
}
.token {
    color: #444444;height: 35px;font-size: 30px;
    margin-top: 24px;
    margin-left: 10px;

}
.token_list {
    height: 16px;
    font-size: 14px;
    color: #999999;
    margin: 8px;

}
.order-body {
    width: 1600px;
    height: 200px;
    background-color: #FFFFFF;
    float: left;
    margin:10px 25px;
}
/deep/ .title-box .el-input__inner {
    background-color: #F5F5F5;
}

/deep/ .el-tabs__item {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    width: 120px;
    font-weight: 600;
}

.order {
    height: calc(100vh - 60px);
    background-color: #F2F2F2;
}
.order .search-btn {
    padding: 6px 12px;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 5px;
    background-color: #2970FF;
}
.order .title-box {
    width: 100%;
    height: 46px;
    background-color: #F5F5F5;
    display: flex;
    line-height: 46px;
    margin-top: 20px;
    padding: 0 20px;
}
.title{
    overflow: hidden;
    overflow-y: scroll;
    max-height: calc(100vh - 360px);
    width: 100%;
}
.title::-webkit-scrollbar {
    display: none;
}
</style>