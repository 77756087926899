<template>
    <div style="width: 100%;height: 100%;">
        <el-tabs v-model="activeIndex" >
            <el-tab-pane v-for="(item,index) in materialFlow" :key="item.type" :label="item.name" :name="item.type.toString()"></el-tab-pane>
        </el-tabs>
        <label v-if="activeIndex == 1">
            <freightTemplate></freightTemplate>
        </label>
        <label v-if="activeIndex == 2" style="width: 100%;height: 100%">
             <zoneTemplate></zoneTemplate>
        </label>
        <label v-if="activeIndex == 3">

        </label>
        <label v-if="activeIndex == 4" style="width: 100%;height: 100%">
             <addressBase></addressBase>
        </label>
    </div>
</template>
<script>
import zoneTemplate from  "../materialFlow/zoneTemplate"
import addressBase from "../materialFlow/addressBase"
import freightTemplate from "../materialFlow/freightTemplate"
export default {
    components: {
        zoneTemplate,
        addressBase,
        freightTemplate
    },

    computed: {

    },
    created:function(){

    },
    data () {
        return {
            activeIndex:'1',
            materialFlow:[
                {name:'运费模板设置',type:'1'},
                {name:'区域限售模板',type:'2'},
                {name:'同城配送安装',type:'3'},
                {name:'地址库',type:'4'},
            ]
        }
    },
    mounted:function(){
        this.activeIndex = this.materialFlow[0].type.toString();
    },
    methods:{

    }
}
</script>
<style scoped>
/deep/ .el-menu--horizontal>.el-menu-item.is-active {
    font-size: 16px;
    color: #2970FF;
    font-weight: bold;
}
/deep/ .el-menu--horizontal>.el-menu-item {
    font-size: 16px;
    font-weight: bold;
}
/deep/ .el-tabs__item.is-active {
    font-size: 16px;
    color:#2970FF ;
}
/deep/ .el-tabs__active-bar {
    background:#2970FF ;
}
/deep/ .el-tabs__item {
    font-size: 16px;
}
</style>