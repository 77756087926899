<template>
    <div style="width: 100%;height: 100%;">
        <div style="width: 100%;height: 100%;margin-left: 30px;">
           <div style="width: 400px;float: left">
               <el-input v-model="searchid" placeholder="输入模板ID搜索"></el-input>
           </div>
            <div style="width: 400px;float: left;margin:0 30px;">
                <el-input v-model="searchname" placeholder="输入模板名称搜索"></el-input>
            </div>
            <div style="float: left"><el-button plain style="height: 34px">筛选</el-button></div>
            <div style="float: right;margin-right: 50px"><el-button type="primary">新增限售模板</el-button></div>
        </div>
        <div style="width: 100%;height: 100%;">
            <template>
                <el-table
                    :data="region"
                    stripe
                    style="width: 100%">
                    <el-table-column
                        prop="id"
                        label="模板ID"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="模板名称"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="restrictsales"
                        label="中国限售地区"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="time"
                        label="最近更新时间"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="操作"
                        align="center">
                        <template>
                            <div style="color: #2970FF;cursor: pointer">编辑限售区域</div>
                            <div style="color: #2970FF;cursor: pointer">删除</div>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
    </div>
</template>
<script>

export default {
    components: {

    },

    computed: {

    },
    created:function(){

    },
    data () {
        return {
            searchid:'',
            searchname:'',
            region:[
                {id:'1100010044576141',name:'偏远地区',restrictsales:'西藏自治区、宁夏回族自治区、新疆维吾尔自治区 青海省、台湾（南投县/埔里镇、台北市）',time:"2023-07-21 16:50:46"},
                {id:'1100010044576141',name:'偏远地区',restrictsales:'西藏自治区、宁夏回族自治区、新疆维吾尔自治区 青海省、台湾（南投县/埔里镇、台北市）',time:"2023-07-21 16:50:46"},
                {id:'1100010044576141',name:'偏远地区',restrictsales:'西藏自治区、宁夏回族自治区、新疆维吾尔自治区 青海省、台湾（南投县/埔里镇、台北市）',time:"2023-07-21 16:50:46"},
                {id:'1100010044576141',name:'偏远地区',restrictsales:'西藏自治区、宁夏回族自治区、新疆维吾尔自治区 青海省、台湾（南投县/埔里镇、台北市）',time:"2023-07-21 16:50:46"}
            ]
        }
    },
    mounted:function(){

    },
    methods:{

    }
}
</script>
<style scoped>
/deep/ .el-input__inner {
    height: 34px;
}
/deep/ .el-button {
    padding: 9px 20px;
}
/deep/ .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
    background: #F6F6F6;
}
</style>