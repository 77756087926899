<template>
    <div style="width: 100%;height: 100%;background: #FFFFFF;overflow: hidden">
        <div style="border: 2px solid #EBEBEB;width: 100%;clear: both;"></div>
        <div style="display: flex;margin: 21px 0 0 30px;">
            <div  style="width: 267px;height: 38px;">
                <el-input  placeholder="输入案例名称或案例ID搜索" suffix-icon="el-icon-search" v-model="search"></el-input>
            </div>
            <div style="font-size: 16px;text-align: center;margin:10px 20px">案例分类</div>
            <div  style="height: 38px;">
                <el-cascader style="width: 260px;" v-model="list" :options="type_list"></el-cascader>
            </div>
            <div style="font-size: 16px;text-align: center;margin: 10px 20px;">金额范围</div>
            <div style="margin: 10px;">-</div>
            <!--   最大价格    -->
            <div style="height: 38px;">
                <el-input style="width: 110px;" placeholder="0.00" prefix-icon="el-icon-coin" type="number" v-model="maxPrice"></el-input>
            </div>
            <div  style="font-size: 16px;text-align: center;margin: 10px 20px;">案例状态</div>
            <div style="width: 140px;height: 38px;">
                <el-select v-model="value" placeholder="请选择">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div style="width: 72px;height: 38px;margin-left: 20px;">
                <el-button plain>筛选</el-button>
            </div>
            <div  style="width: 170px;height: 38px;margin-left: 20px">
                <el-button class="el-icon-s-promotion" type="primary" @click="a('/shop/addCase')">发布新案例</el-button>
            </div>
        </div>
        <div class="list_height" style="width: 95%;margin: 10px 30px;overflow: auto">
            <el-table :data="ShopCase " stripe style="width: 100%;">
                <el-table-column  prop="type" label="案例分类" width="150">
                    <template >
                        <div type="success" style="display: inline-block; vertical-align: middle;">品牌全案</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="title"
                    label="案例名称"
                    width="600">
                    <template slot-scope="scope" style="display: flex">
                        <div style="width: 84px;height: 84px;background: #D6D6D6;display: inline-block;">
                            <img :src="scope.row.img" style="width: 84px;height: 84px;">
                        </div>
                        <div style="display: inline-block;width: 400px;margin-left: 10px;font-size: 16px;">{{ scope.row.title }}<div style="color:#999999;font-size: 14px;">id:<span>{{ scope.row.id }}</span></div></div>
                    </template>
                </el-table-column>
                <el-table-column  label="案例金额" width="150">
                    <template  slot-scope="scope">
                        <div type="success" class="el-icon el-icon-coin" style="display: inline-block;"></div>
                        <div  type="success" style="display: inline-block;margin-left: 5px">{{ scope.row.money }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="timestamp" label="创建时间" width="200">
                    <template >
                        <div >
                            <div type="success">2023-06-01 10:20:24</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="modificationTime" label="修改时间" width="200">
                    <template >
                        <div >
                            <div type="success">2023-06-01 10:20:24</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="price"
                    label="案例状态">
                    <template >
                        <div>
                            <el-tag type="success" >展示中</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="操作">
                    <template slot-scope="scope">
                        <div type="success" class="button-column" style="display: flex;justify-content: center;align-items: center;margin-right: 50px;">
                            <el-button type="text" size="small">下架</el-button>
                            <el-button type="text" size="small" @click="editcase(scope.row.id)">编辑案例</el-button>
                            <el-button type="text" size="small" @click="deleteCase(scope.row)">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>

export default {
    props:{
        myshop:{
            type:Object,
            default(){
                return false
            }
        },

    },
    components: {

    },
    watch:{
        myshop:{
            handler(){
                console.log(this.myshop)
            }
        },

    },
    comments:{

    },

    data() {
        return {
            search:'',
            list:'',
            type_list:[],
            // 最小价格
            minPrice:'',
            // 最大价格
            maxPrice:'',
            options: [{
                value: '1',
                label: '草稿'
            }, {
                value: '2',
                label: '展示中'
            }, {
                value: '3',
                label: '仓库中'
            }, {
                value: '4',
                label: '全部状态'
            }],
            value: '',
            ShopCase:[],
        }
    },

    mounted() {
        let that = this;
        that.getTypeList();
        that.getShopCase();
    },
    computed: {

    },

    methods: {
        // 服务分类
        getTypeList(){
            var that = this;
            that.newApi.getTypeList({
                lv: 3,
            }).then((res)=>{
                that.type_list = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        getShopCase(){
            var that= this;
            that.newApi.getShopCase({
                shopId:that.myshop.id,
                page:1,
                pageSize:10,
            }).then((ret)=>{
                that.ShopCase = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        deleteCase(row){
            let that = this;
            that.newApi.deleteShopCase({
                id:row.id
            }).then((ret)=>{
                that.utils.sus(ret.data);
                that.getShopCase();
            }).catch((err)=>{
                console.log(err)
            })
        },
        editcase(id){
            this.$router.push({path:'/shop/addcase',query:{id:id}});
        }

    },

}

</script>
<style scoped>


/deep/ .cell {
    color: #444444;
    font-size: 16px;
}

.list_height {
    height: 690px;
}
.list_height::-webkit-scrollbar {
    display: none;
}
.button-column {
    display: flex;
    flex-direction: column;
    text-align: center;
}
.list_height {
    height: 750px;
}
.list_height::-webkit-scrollbar {
    display: none;
}
</style>