<template>
    <div style="width: 100%;height: 100%;">
        <div style="float: right; margin: 0 30px 10px 0;"><el-button type="primary">添加新地址</el-button></div>
        <template>
            <el-table
                :data="address "
                border
                style="width: 100%">
                <el-table-column
                    label="发货地址" align="center">
                    <template>
                        <el-radio v-model="addressradio" label="1">默认</el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    label="退货地址" align="center">
                    <template>
                        <el-radio v-model="addressradio" label="2">默认</el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="localarea"
                    label="所在地址" align="center">
                </el-table-column>
                <el-table-column
                    prop="detailedaddress"
                    label="详细地址" align="center">
                </el-table-column>
                <el-table-column
                    prop="contactperson"
                    label="联系人" align="center">
                </el-table-column>
                <el-table-column
                    prop="telephone"
                    label="联系电话" align="center">
                </el-table-column>
                <el-table-column
                    label="操作" align="center">
                    <template>
                        <div style="color: #2970FF;cursor: pointer">编辑地址</div>
                        <div style="color: #2970FF;cursor: pointer">删除</div>
                    </template>
                </el-table-column>
            </el-table>
        </template>
    </div>
</template>
<script>

export default {
    components: {

    },

    computed: {

    },
    created:function(){

    },
    data () {
        return {
            address:[
                {localarea:'新疆维吾尔自治区乌鲁木齐市新 市区喀什东路街道',detailedaddress:'喀什东路植物园小区（2期）4栋 1层9号商铺',contactperson:'赵大宝',telephone:'13625412365'},
                {localarea:'新疆维吾尔自治区乌鲁木齐市新 市区喀什东路街道',detailedaddress:'喀什东路植物园小区（2期）4栋 1层9号商铺',contactperson:'赵大宝',telephone:'13625412365'},
                {localarea:'新疆维吾尔自治区乌鲁木齐市新 市区喀什东路街道',detailedaddress:'水磨沟区',contactperson:'赵大宝',telephone:'13625412365'},
                {localarea:'新疆维吾尔自治区乌鲁木齐市新 市区喀什东路街道',detailedaddress:'喀什东路植物园小区（2期）4栋 1层9号商铺',contactperson:'赵大宝',telephone:'13625412365'},
                {localarea:'新疆维吾尔自治区乌鲁木齐市新 市区喀什东路街道',detailedaddress:'新市区新天润',contactperson:'赵大宝',telephone:'13625412365'}
            ],
            addressradio:'1',
        }
    },
    mounted:function(){

    },
    methods:{

    }
}
</script>
<style scoped>
/deep/ .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
    background: #F6F6F6;
}
/deep/ .el-input__inner {
    height: 34px;
}
/deep/ .el-button {
    padding: 9px 20px;
}
</style>